import { formatMoney } from '@shopify/theme-currency';

export function money(amount) {
  return formatMoney(amount, '${{amount}}');
}

export function total(value) {
  return formatMoney(value + (15 / 100) * value, '${{amount}}');
}

export function gst(value) {
  return formatMoney((15 / 100) * value, '${{amount}}');
}

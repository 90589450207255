<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="5.75"
      y1="3.27835e-08"
      x2="5.75"
      y2="11.5"
      :stroke="props.stroke"
      stroke-width="1.5"
    />
    <line
      x1="11.5"
      y1="5.75"
      x2="-6.17988e-08"
      y2="5.75"
      :stroke="props.stroke"
      stroke-width="1.5"
    />
  </svg>
</template>

<script setup>
const props = defineProps({
  stroke: {
    type: String,
    required: false,
    default: '#303030',
  },
});
</script>



<template>
  <svg
    width="12"
    height="2"
    viewBox="0 0 12 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="11.5"
      y1="0.75"
      x2="-6.17988e-08"
      y2="0.749999"
      stroke="#303030"
      stroke-width="1.5"
    />
  </svg>
</template>

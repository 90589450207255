<template>
  <div class="product__quantity__wrap">
    <div class="product__quantity__error">
      <p class="small" v-if="picker.maxError">
        <span>Max reached</span>
      </p>
    </div>
    <div
      :class="[
        'product__quantity',
        source !== '' ? 'product__quantity--' + source : '',
      ]"
      
    >
      <button
        name="minus"
        type="button"
        class="product__quantity__minus"
        :disabled="!available || disabled"
        @click.prevent="decrementQuantity($event)"
      >
        <icon-minus />
      </button>
      <input
        class="product__quantity__input"
        style="-moz-appearance: textfield"
        type="number"
        name="product-quantity"
        :value="quantity"
        min="1"
        :max="max_quantity"
        step="0"
        :disabled="!available || disabled"
        @change.prevent="updateQuantity(Number($event.target.value))"
      />
      <button
        name="plus"
        type="button"
        class="product__quantity__plus"
        :disabled="!available || disabled"
        @click.prevent="incrementQuantity($event)"
      >
        <icon-plus />
      </button>
    </div>
  </div>
</template>

<script setup>
import { reactive, defineEmits } from 'vue';
import IconMinus from './icons/IconMinus.vue';
import IconPlus from './icons/IconPlus.vue';

const props = defineProps({
  source: {
    type: String,
    required: false,
    default: () => '',
  },
  quantity: {
    type: Number,
    required: true,
  },
  max_quantity: {
    type: Number,
    required: false,
    default: () => null,
  },
  available: {
    type: Boolean,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false
  }
});

const picker = reactive({
  quantity: props.quantity,
  maxError: false,
});

const emit = defineEmits(['updateQuantity']);

const updateQuantity = (input) => {
  picker.quantity = input;
  if (props.source !== 'list') {
    if (picker.quantity >= props.max_quantity) {
      picker.maxError = true;
      picker.quantity = props.max_quantity;
      setTimeout(() => {
        picker.maxError = false;
      }, 2000);
    } else if (
      picker.maxError == true &&
      picker.quantity < props.max_quantity
    ) {
      picker.maxError = false;
    }
  }
  if (picker.quantity < 1) {
    picker.quantity = 1
  };
  emit('updateQuantity', picker.quantity);
  return picker.quantity;
};

const decrementQuantity = () => {
  if (picker.maxError == true) {
    picker.maxError = false;
  }
  if (picker.quantity == 1) return;
  picker.quantity--;
  emit('updateQuantity', picker.quantity);
};

const incrementQuantity = () => {
  if (picker.quantity == props.max_quantity) {
    picker.maxError = true;
    setTimeout(() => {
      picker.maxError = false;
    }, 2000);
  }
  if (picker.quantity == props.max_quantity) return;
  picker.quantity++;
  emit('updateQuantity', picker.quantity);
};
</script>

<template>
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.000107765 8.84621L8.84617 0.000156849L10 1.15399L1.15394 10L0.000107765 8.84621Z"
      fill="#303030"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.15383 0L9.99989 8.84606L8.84606 9.99989L0 1.15383L1.15383 0Z"
      fill="#303030"
    />
  </svg>
</template>
